.calc-modal__disclaimer-headline {
  font-size: 1.1em;
  font-weight: 700;
}
.calc-modal__disclaimer-text {
  font-size: 0.9em;
}

.calc-modal__feeding-headline {
  font-size: 1.4em;
  font-weight: 500;
  padding-left: 0.65em;
}

.calc-modal-feeding-card-title {
  font-size: 1.3em;
  font-weight: 700;
  padding-right: 1em;
}

.calc-modal-feeding-card-text {
  font-size: 0.9em;
}

@media (min-width: 768px) {
  .calc-modal {
    --height: 42em;
  }
}
