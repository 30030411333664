:root {
  --ion-grid-width-md: 700px;
  --ion-grid-width-lg: 700px;
  --ion-grid-width-xl: 700px;
}

.toolbar-icons {
  font-size: 1.5em;
  padding-left: 0.2em;
  padding-right: 0.2em;
}

ion-toast {
  font-size: 1.1em;
  font-weight: 600;
}

.page-title {
  font-size: 2em;
  font-weight: 500;
  padding-left: 0.3em;
  color: rgb(223, 223, 223);
  display: none;
}

@media (min-height: 46.875rem) {
  .page-title {
    display: initial;
  }
}

.display-flex {
  display: flex !important;
}

.align-items-center {
  align-items: center !important;
}
