.calc-label {
  font-size: 0.9em;
  font-weight: 500;
  margin-top: 0.3em;
  margin-bottom: 0.1em;
}

.calc-heading {
  font-size: 1.1em;
  font-weight: 600;
  display: block;
  margin-top: 1em;
  margin-bottom: 0.1em;
}

.calc__growth-description {
  font-size: 0.9em;
  color: var(--ion-color-dark-tint);
  padding-top: 1em;
}

.waterTemperature-value {
  font-size: 1.1em;
  font-weight: 700;
  margin-top: 0.7em;
  display: block;
}

.calc-row {
  border-bottom: 1px solid rgb(245, 245, 245);
}

.calc-button {
  margin-top: 0.5em;
  margin-bottom: 0.2em;
}
