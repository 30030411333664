/* ion-content {
  --ion-background-color: #f5f3f3;
} */

.logo {
  height: 4.5em;
}

/* @media (min-width: 650px) {
  body {
    justify-content: center;
    max-width: 650px;
    border: 1px rgb(187, 187, 187);
  }
} */
