.fishModal-label {
  font-size: 1.1em;
  font-weight: 500;
  display: block;
  margin-top: 0.3em;
  margin-bottom: 0.1em;
}

.bodyShape-label {
  font-size: 1em;
  font-weight: 500;
  margin-top: 0.3em;
  margin-bottom: 0.1em;
}

.fishModal-row {
  border-bottom: 1px solid rgb(239, 239, 239);
  font-size: 0.9em;
}

.fishModal-row:hover,
.fishModal-row:active,
.fishModal-row:focus {
  border-bottom: 1px solid var(--ion-color-primary);
}

.bodyShape-title {
  font-size: 0.9em;
  color: var(--ion-color-dark-tint);
}

.bodyShape-description {
  font-size: 0.9em;
  color: var(--ion-color-dark-tint);
}

.saveButton {
  margin-top: 1em;
}

@media (min-width: 768px) {
  .fish-modal {
    --height: 42em;
  }
}
