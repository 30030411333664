.tank-headline {
  font-size: 1.5em;
  font-weight: 700;
}

.tank-headline:hover,
.tank-headline:active {
  color: var(--ion-color-primary);
  cursor: pointer;
}

.tank-add-fish {
  font-size: 1.1em;
  font-weight: 700;
}

.tank-add-fish:hover,
.tank-add-fish:active {
  color: var(--ion-color-primary);
  cursor: pointer;
}

.fish-entry-header {
  border-bottom: 1px solid rgb(190, 190, 190);
  font-weight: 700;
}

.fish-entry-row {
  border-bottom: 1px solid rgb(239, 239, 239);
  font-size: 0.9em;
  padding-top: 0.1em;
  padding-bottom: 0.1em;
}

.fish-entry-row:hover,
.fish-entry-row:active {
  color: var(--ion-color-primary);
  cursor: pointer;
}

.fish-entry-row:last-child {
  border: initial;
}
